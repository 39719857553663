<template>
  <div>
    <div class="statistics-box">
      <div class="title">
        <div class="name">工时状态数字统计</div>
        <div class="time">
          <el-button
            type="text"
            :class="{'ash': timeType != 1 }"
            @click="handleChangeType(1)"
          >本周</el-button>
          <el-button
            type="text"
            :class="{'ash': timeType != 2 }"
            @click="handleChangeType(2)"
          >本月</el-button>
          <el-button
            type="text"
            :class="{'ash': timeType != 3 }"
            @click="handleChangeType(3)"
          >本年</el-button>
          <div style="margin-left: 20px; margin-right: 8px">选择时间</div>
          <div style="display: flex">
            <el-date-picker
              v-model="temporaryStart"
              type="date"
              style="width: 130px"
              :picker-options="pickerOptions"
              @change="hadnleChangeTime"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            ></el-date-picker>
            <div style="margin: 0 8px">至</div>
            <el-date-picker
              v-model="temporaryEnd"
              type="date"
              style="width: 130px"
              :picker-options="pickerOptions"
              @change="hadnleChangeTime"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            ></el-date-picker>
          </div>
        </div>
      </div>
      <div class="echarts-box">
        <div class="echarts">
          <div ref="lineBox" style="height: 410px; width: 100%"></div>
          <div class="num-box">
            <!-- <div>
              <span>
                {{
               `${workingData[0] || 0}时${workingData[1] || 0}分` 
                }}
              </span>
              <div>行走工时</div>
            </div>-->
            <div>
              <span>
                {{
                `${workingData[2] || 0}时${workingData[3] || 0}分`
                }}
              </span>
              <div>通电工时</div>
            </div>
            <div>
              <span>
                {{
                `${workingData[4] || 0}时${workingData[5] || 0}分`
                }}
              </span>
              <div>怠速工时</div>
            </div>
            <div>
              <span>
                {{
                `${workingData[6] || 0}时${workingData[7] || 0}分`
                }}
              </span>
              <div>作业工时</div>
            </div>
            <div>
              <span>
                {{
                `${workingData[8] || 0}时${workingData[9] || 0}分`
                }}
              </span>
              <div>熄火工时</div>
            </div>
          </div>
        </div>
        <div class="others">
          <div>
            <div>
              <span>
                {{
                `${Math.floor(numData[5] / 3600) || 0}时${Math.floor(
                (numData[5] / 60) % 60
                ) || 0}分`
                }}
              </span>
              <div>日均工时</div>
            </div>
            <div>
              <span>{{numData[6] ? Number(numData[6]).toFixed(2) : 0 }}%</span>
              <div>周利用率</div>
            </div>
          </div>
        </div>
        <div class="notes">
          <div>
            <div v-for="(item) in workList" :key="item.id" class="con">
              <Iconfont
                name="icon-circle"
                :size="18"
                :color="item.color"
                class="iconfont"
              ></Iconfont>
              <span>{{item.text}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin: 10px 0">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
        align="center"
      >
        <el-table-column label="序号" type="selection" width="55"></el-table-column>
        <el-table-column label="序号" type="index" width="55" align="center"></el-table-column>

        <el-table-column label="日期" width="120" prop="dateTime" align="center"></el-table-column>

        <el-table-column
          prop="vehicleAbsName"
          label="车辆/车组"
          width="300"
          align="center"
        >
          <!-- <template slot-scope="scope">{{ scope.row.date }}</template> -->
        </el-table-column>

        <el-table-column
          prop="statue"
          label="工时状态"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span
              :class="[ scope.row.statue > 5 || scope.row.statue == '' ? 'NeverOnline' : '',colorData[scope.row.statue]]"
            >{{ statueNameList[scope.row.statue] || '从未上线' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="duration"
          label="工时时间"
          show-overflow-tooltip
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ `${Math.floor(scope.row.duration/3600)}时${Math.floor(scope.row.duration/60%60)}分`}}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="productType"
          label="产品线"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column
          prop="brandType"
          label="品牌"
          show-overflow-tooltip
          align="center"
        ></el-table-column>-->
      </el-table>
      <div
        class="loadingMore"
        v-if="securityList.length && securityList.length > 0"
        @click="DelayAssignList"
      >点击加载更多</div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { workStatistics, workTables } from "@/api/getData";
import { getWeek, getTimes } from "@/utils/getDate";
import dayjs from "dayjs";
export default {
  name: "alarmStatistics",
  data() {
    return {
      startTime: getWeek()[1],
      endTime: getWeek()[2],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      numData: {
        0: 0,
        1: 0,
        2: 0,
      },
      workingData: [],
      temporaryStart: "",
      temporaryEnd: "",
      timeType: 1,
      tableData: [],
      securityList: [],
      echartsData: [],
      workList: [
        // {
        //   id: 1,
        //   text: "行走状态：车辆正在行驶中的状态",
        //   color: "#FFBB33" '行走',
        // },
        {
          id: 2,
          text: "通电状态：车辆通电但未启动发动机状态",
          color: "#61BBFF"
        },
        { id: 3, text: "怠速状态：发动机启动处于空挡状态", color: "#FFF27D" },
        { id: 4, text: "作业状态：车辆正在负荷运行状态", color: "#B7E77A" },
        { id: 5, text: "熄火状态：发动机熄火断电状态", color: "#D9D9D9" }
      ],
      statueNameList: ['怠速', '行走', '作业', '通电', '熄火'],
      vehicleIds: [],
      requestNum: 0,
      colorData: {
        0: 'yellow',
        1: 'orange',
        2: 'Online',
        3: 'blue',
        4: 'NeverOnline',
        5: 'NeverOnline',
      },
      lineBox: null
    };
  },
  props: {
    option: {
      type: Array,
      default: function () {
        return [];
      }
    },
    vehicleId: {
      type: Array,
      default: []
    },
    groupIds: {
      type: Array,
      default: function () {
        return [];
      }
    },
    tableDatas: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  watch: {
    vehicleId: {
      handler() {
        this.requestNum = 1;
        this.getWorkStatistics();
        this.getWorkTables();
      },
      deep: true,
      // immediate: true,
    },
    // groupIds: {
    //   handler() {
    //     this.getWorkStatistics();
    //     this.getWorkTables();
    //   },
    //   deep: true
    // }
  },
  created() {
    this.vehicleIds = this.vehicleId;
    this.echartsData = this.option;
    this.tableData = this.tableDatas;
    this.getWorkData();
  },
  mounted() {
    // this.getWorkTables();
    // this.getWorkData();
  },
  methods: {
    //获取工时柱状图数据
    async getWorkStatistics() {
      if (this.startTime && !this.endTime) {
        this.$message({
          message: '请选择结束时间！',
          type: 'warning'
        });
        return false;
      }
      if (!this.startTime && this.endTime) {
        this.$message({
          message: '请选择开始时间！',
          type: 'warning'
        });
        return false;
      }
      if (this.startTime && this.endTime && (new Date(this.startTime) > new Date(this.endTime))) {
        this.$message({
          message: '请选择正确的开始时间和结束时间！',
          type: 'warning'
        });
        return false;
      }
      this.vehicleIds = this.vehicleId;
      await workStatistics({
        pageNumber: 1,
        pageSize: 10,
        vehicleIds: this.vehicleIds,
        startTime: this.startTime,
        endTime: this.endTime,
        groupIds: this.groupIds,
        intervalMark: this.timeType,
        //   flag: true,
        deviceStates: [],
        productLines: [],
        vehicleBrands: []
      }).then(res => {
        if (res.flag == 1) {

          //获取柱状图数据
          if (res.obj && res.obj.vehicleStatisticalList) {
            // let vehicleStatisticalList = res.obj.vehicleStatisticalList.reverse();
            let vehicleStatisticalList = res.obj.vehicleStatisticalList;
            this.echartsData[1] = vehicleStatisticalList;

            //获取文字描述数据
            let numData = {
              0: 0,
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0
            };
            // numData[0] = res.obj.walkTime ? res.obj.walkTime : 0;
            // numData[1] = res.obj.electrifyTime ? res.obj.electrifyTime : 0;
            // numData[2] = res.obj.idleTime ? res.obj.idleTime : 0;
            // numData[3] = res.obj.workTime ? res.obj.workTime : 0;
            // numData[4] = res.obj.flameOutTime ? res.obj.flameOutTime : 0;
            numData[5] = res.obj.dayAverage ? res.obj.dayAverage : 0;
            numData[6] = res.obj.utilization ? res.obj.utilization : 0;
            this.echartsData[0] = numData;
            this.$message({
              type: "success",
              duration: 1000,
              message: "工时统计获取成功",
            });
          }
        };
      });
      this.getWorkData();
    },
    //获取工时网格数据
    async getWorkTables() {
      if (this.startTime && !this.endTime) {
        this.$message({
          message: '请选择结束时间！',
          type: 'warning',
          duration: 1000
        });
        return false;
      }
      if (!this.startTime && this.endTime) {
        this.$message({
          message: '请选择开始时间！',
          type: 'warning',
          duration: 1000
        });
        return false;
      }

      if (this.startTime && this.endTime && (new Date(this.startTime) > new Date(this.endTime))) {
        this.$message({
          message: '请选择正确的开始时间和结束时间！',
          type: 'warning'
        });
        return false;
      }
      if (this.startTime) {
        this.dateStr = this.startTime.substr(10);
      }
      this.securityList = [];
      this.tableData = [];
      this.vehicleIds = this.vehicleId;
      workTables({
        pageNumber: 1,
        pageSize: 10,
        vehicleIds: this.vehicleIds,
        startTime: this.startTime,
        endTime: this.endTime,
        groupIds: this.groupIds,
        intervalMark: this.timeType,
        //   flag: false,
        deviceVehicleIds: [],
        productVehicleIds: [],
        brandVehicleIds: []
      }).then(res => {
        if (res.flag == 1) {
          if (res) {
            const { rows } = res;
            if (rows && rows.length) {
              const rowsTime = setTimeout(() => (this.securityList = rows, this.DelayAssignList(), clearTimeout(rowsTime)), 600);
            }
            // rows ? setTimeout(()=> (this.securityList = rows,this.DelayAssignList()), 600) : [];
            // this.DelayAssignList();
          }
        };
      });
      // this.getWorkData();
    },
    async getWorkData() {
      let { numData, xData, series } = await this.processPostData();
      this.numData = numData;
      let op = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (params) {
            let str = '';
            str += params[0].axisValue + '<br>';
            params.map(val => {
              str += val.marker + val.seriesName + '：' + `${ parseInt(val.value) }时${ Math.floor((val.value * 3600 / 60) % 60) }分 ` + '<br>';
            });
            return str;
          },
        },
        legend: {
          bottom: -10,
          left: "center",
          padding: 10,
          itemWidth: 10, //设置宽度
          itemHeight: 10, //设置高度
          itemGap: 18,
          icon: "roundRect",
          textStyle: {
            fontSize: '14px',
            fontWeight: 700
          },
          // "行走", 
          data: ["通电", "怠速", "作业", "熄火"]
        },
        grid: {
          left: "3%",
          top: "12%",
          right: "4%",
          bottom: "16%",
          containLabel: true,
        },
        xAxis: [
          {
            bottom: "5%",
            type: "category",
            name: "日期",
            nameGap: 5,
            axisLine: {
              lineStyle: {
                type: "dashed",
                color: "#cccccc",
              },
            },
            axisLabel: {
              show: true,
              //   interval: 0,
              textStyle: {
                color: "#333333", //更改坐标轴文字颜色
              },
            },
            splitNumber: 2,
            maxInterval: 25,
            minInterval: 25,
            data: xData,
          },
        ],
        yAxis: [
          {
            name: "工时/小时",
            type: "value",
            axisLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#cccccc",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333", //更改坐标轴文字颜色
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#EFEFEF",
              },
            },
          },
        ],
        dataZoom: [
          {
            type: "slider",
            show: true,
            // handleSize: 5,
            height: 12,
            startValue: 0,
            endValue: 6,
            filterMode: "filter",
          },
          {
            type: "inside",
            xAxisIndex: [0],
            startValue: 0,
            endValue: 6,
          },
        ],
        series: [
          // {
          //   name: "行走",
          //   data: series[0],
          //   type: 'bar',
          //   stack: 'Ad',
          //   itemStyle: {
          //     normal: {
          //       color: '#FFBB33', //折点颜色
          //     }
          //   }
          // },
          {
            name: "通电",
            data: series[1],
            type: 'bar',
            stack: 'Ad',
            itemStyle: {
              normal: {
                color: '#61BBFF', //折点颜色
              }
            },
          },
          {
            name: "怠速",
            data: series[2],
            type: 'bar',
            stack: 'Ad',
            itemStyle: {
              normal: {
                color: '#FFF27D', //折点颜色
              }
            },
          },
          {
            name: "作业",
            data: series[3],
            type: 'bar',
            stack: 'Ad',
            itemStyle: {
              normal: {
                color: '#B7E77A', //折点颜色
              }
            },
          }, {
            name: "熄火",
            data: series[4],
            type: 'bar',
            stack: 'Ad',
            itemStyle: {
              normal: {
                color: '#D9D9D9', //折点颜色
              }
            },
          }
        ],
      };
      this.lineBox ? this.lineBox.clear() : this.lineBox = echarts.init(this.$refs.lineBox, null, {});
      this.lineBox.setOption(op);
    },
    handleSelectionChange(val) {
    },
    tableRowClassName({ row, rowIndex }) {
      // "check-row"
      return "";
    },
    handleChangeType(val) {
      this.startTime = getTimes(val)[0];
      this.endTime = getTimes(val)[1];
      this.timeType = val;
      this.getWorkTables();
      this.getWorkStatistics();
    },
    hadnleChangeTime(val) {
      if (this.temporaryStart && !this.temporaryEnd) {
        this.$message({
          message: '请选择结束时间！',
          type: 'warning'
        });
        return false;
      } else if (!this.temporaryStart && this.temporaryEnd) {
        this.$message({
          message: '请选择开始时间！',
          type: 'warning'
        });
        return false;
      } else if (this.temporaryStart && this.temporaryEnd && (new Date(this.temporaryStart) > new Date(this.temporaryEnd))) {
        this.$message({
          message: '请选择正确的开始时间和结束时间！',
          type: 'warning'
        });
        return false;
      } else {
        this.startTime = dayjs(this.temporaryStart).format("YYYY-MM-DD 00:00:00");
        this.endTime = dayjs(this.temporaryEnd).format("YYYY-MM-DD 23:59:59");
        this.timeType = 0;
        this.getWorkTables();
        this.getWorkStatistics();
      }
    },
    processPostData() {
      let numData = {};
      let xData = [];
      let series = {
        0: [],
        1: [],
        2: [],
        3: [],
        4: []
      };
      if (this.echartsData && this.echartsData[0]) {
        numData = this.echartsData[0];
      };

      let walkingHours = 0;
      let walkingMinutes = 0;

      let electricityTimeHours = 0;
      let electricityTimeMinutes = 0;

      let idleSpeedHours = 0;
      let idleSpeedMinutes = 0;

      let workTimeHours = 0;
      let workTimeMinutes = 0;

      let shutdownTimeHours = 0;
      let shutdownTimeMinutes = 0;


      if (this.echartsData && this.echartsData[1]) {

        //柱状图形下面数据
        this.echartsData[1].map((item) => {
          //行走工时
          walkingHours += Math.floor(item.walkingTime / 3600);
          walkingMinutes += Math.floor(item.walkingTime / 60 % 60);

          //通电工时

          electricityTimeHours += Math.floor(item.electricityTime / 3600);
          electricityTimeMinutes += Math.floor(item.electricityTime / 60 % 60);

          //怠速工时
          idleSpeedHours += Math.floor(item.idleSpeed / 3600);
          idleSpeedMinutes += Math.floor(item.idleSpeed / 60 % 60);

          //作业工时 
          workTimeHours += Math.floor(item.workTime / 3600);
          workTimeMinutes += Math.floor(item.workTime / 60 % 60);

          //熄火工时
          shutdownTimeHours += Math.floor(item.shutdownTime / 3600);
          shutdownTimeMinutes += Math.floor(item.shutdownTime / 60 % 60);

        });

        walkingHours = walkingHours + Math.floor(walkingMinutes / 60);
        walkingMinutes = walkingMinutes % 60;

        electricityTimeHours = electricityTimeHours + Math.floor(electricityTimeMinutes / 60);
        electricityTimeMinutes = electricityTimeMinutes % 60;

        idleSpeedHours = idleSpeedHours + Math.floor(idleSpeedMinutes / 60);
        idleSpeedMinutes = idleSpeedMinutes % 60;

        workTimeHours = workTimeHours + Math.floor(workTimeMinutes / 60);
        workTimeMinutes = workTimeMinutes % 60;

        shutdownTimeHours = shutdownTimeHours + Math.floor(shutdownTimeMinutes / 60);
        shutdownTimeMinutes = shutdownTimeMinutes % 60;

        this.workingData = [walkingHours, walkingMinutes, electricityTimeHours, electricityTimeMinutes, idleSpeedHours, idleSpeedMinutes, workTimeHours, workTimeMinutes, shutdownTimeHours, shutdownTimeMinutes];

        for (const key in this.echartsData[1]) {
          if (this.timeType == 3) {
            xData.push(this.echartsData[1][key].dateDay.substr(0, 7));
          } else {
            xData.push(this.echartsData[1][key].dateDay);
          }
          if (this.echartsData[1][key]) {
            if (this.echartsData[1][key].walkingTime) {
              series[0].push(this.echartsData[1][key].walkingTime / 3600);
            } else {
              series[0].push(0);
            }
            if (this.echartsData[1][key].electricityTime) {
              series[1].push(this.echartsData[1][key].electricityTime / 3600);
            } else {
              series[1].push(0);
            }
            if (this.echartsData[1][key].idleSpeed) {
              series[2].push(this.echartsData[1][key].idleSpeed / 3600);
            } else {
              series[2].push(0);
            }
            if (this.echartsData[1][key].workTime) {
              series[3].push(this.echartsData[1][key].workTime / 3600);
            } else {
              series[3].push(0);
            }
            if (this.echartsData[1][key].shutdownTime) {
              series[4].push(this.echartsData[1][key].shutdownTime / 3600);
            } else {
              series[4].push(0);
            }
          } else {
            series[0].push(0);
            series[1].push(0);
            series[2].push(0);
            series[3].push(0);
            series[4].push(0);
          }
        };
      }
      return { numData, xData, series };
    },
    DelayAssignList() {
      if (this.securityList.length > 0) {
        this.tableData.push(...this.securityList.splice(0, 10));
        // this.listTime = setTimeout(()=>{
        //   clearTimeout(this.listTime);
        //   this.listTime = null;
        //     this.DelayAssignList();
        // }, 1200)
      } else {
        this.$message.errer('列表加载完成!');
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.statistics-box {
  overflow: hidden;
  background-color: #fff;
  padding-bottom: 38px;
  > .title {
    display: flex;
    justify-content: space-between;
    margin: 0 24px;
    line-height: 48px;
    border-bottom: 1px dashed #cccccc;
    > .name {
      position: relative;
      padding-left: 10px;
      font-size: 20px;
      font-weight: bold;
      color: #666666;
    }
    > .name::after {
      content: '';
      position: absolute;
      left: 0px;
      top: 15px;
      height: 16px;
      width: 4px;
      background-color: rgba(66, 120, 201, 1);
    }
    > .time {
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;
      color: #666666;
      > .ash {
        color: #666666;
      }
    }
  }
  > .echarts-box {
    min-width: 1000px;
    display: flex;
    margin-top: 16px;
    > .echarts {
      flex: 1;
      > .num-box {
        display: flex;
        justify-content: space-between;
        width: 800px;
        margin: auto;
        margin-top: 20px;
        > div {
          width: 100px !important;
          text-align: center;
          > div {
            font-size: 12px;
            color: #666666;
            margin-top: 4px;
          }
          > span {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
          }
        }
      }
    }
    > .others {
      width: 10%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        > div {
          text-align: center;
          &:last-child {
            margin-top: 20px;
          }
          > span {
            font-size: 20px;
            font-weight: bold;
            color: #333333;
            font-family: PingFang SC-Bold, PingFang SC;
          }
          > div {
            font-size: 12px;
            color: #666666;
            margin-top: 4px;
          }
        }
      }
    }
    > .notes {
      width: 28%;
      margin-right: 8px;
      margin-left: 4px;
      display: flex;
      align-items: center;
      .con {
        margin: 20px 0;
        .iconfont {
          margin-right: 10px;
        }
        span {
          font-size: 16px;
        }
      }
    }
  }
}
.check-row {
  background-color: rgba(220, 232, 252, 1);
}
.loadingMore {
  padding: 12px;
  text-align: center;
  color: #409eff;
  cursor: pointer;
  background-color: #fff;
}
.loadingMore:hover {
  color: #409eff;
}

::v-deep .el-input--small .el-input__inner {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
}

::v-deep .el-message {
  min-width: 30px !important;
}
</style>


