<template>
  <div>
    <div class="statistics-box">
      <div class="title">
        <div class="name">工时状态占比分析</div>
        <div class="time">
          <el-button
            type="text"
            :class="{ ash: pieTimeType != 1 }"
            @click="handleChangeType(1, 'setPieEcharts')"
          >本周</el-button>
          <el-button
            type="text"
            :class="{ ash: pieTimeType != 2 }"
            @click="handleChangeType(2, 'setPieEcharts')"
          >本月</el-button>
          <el-button
            type="text"
            :class="{ ash: pieTimeType != 3 }"
            @click="handleChangeType(3, 'setPieEcharts')"
          >本年</el-button>
          <div style="margin-left: 20px; margin-right: 8px">选择时间</div>
          <div style="display: flex">
            <el-date-picker
              v-model="pieStartTime"
              type="date"
              style="width: 130px"
              :picker-options="pickerOptions"
              @change="hadnleChangeTime('setPieEcharts')"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            ></el-date-picker>
            <div style="margin: 0 8px">至</div>
            <el-date-picker
              v-model="pieEndTime"
              type="date"
              style="width: 130px"
              :picker-options="pickerOptions"
              @change="hadnleChangeTime('setPieEcharts')"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            ></el-date-picker>
          </div>
        </div>
      </div>
      <div class="echarts-box">
        <div class="echarts">
          <div ref="lineBox" style="height: 410px; width: 100%"></div>
        </div>
        <div class="notes">
          <div v-for="(item) in workList" :key="item.id" class="con">
            <Iconfont
              name="icon-circle"
              :size="18"
              :color="item.color"
              class="iconfont"
            ></Iconfont>
            <span>{{item.text}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="statistics-box" style="margin: 10px 0">
      <div class="title">
        <div class="name">工时状态走势分析</div>
        <div class="time">
          <el-button
            type="text"
            :class="{ ash: lineTimeType != 1 }"
            @click="handleChangeType(1,'setLineEcharts')"
          >本周</el-button>
          <el-button
            type="text"
            :class="{ ash: lineTimeType != 2 }"
            @click="handleChangeType(2,'setLineEcharts')"
          >本月</el-button>
          <el-button
            type="text"
            :class="{ ash: lineTimeType != 3 }"
            @click="handleChangeType(3,'setLineEcharts')"
          >本年</el-button>
          <div style="margin-left: 20px; margin-right: 8px">选择时间</div>
          <div style="display: flex">
            <el-date-picker
              v-model="lineStartTime"
              type="date"
              style="width: 130px"
              :picker-options="pickerOptions"
              @change="hadnleChangeTime('setLineEcharts')"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            ></el-date-picker>
            <div style="margin: 0 8px">至</div>
            <el-date-picker
              v-model="lineEndTime"
              type="date"
              style="width: 130px"
              :picker-options="pickerOptions"
              @change="hadnleChangeTime('setLineEcharts')"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            ></el-date-picker>
          </div>
        </div>
      </div>
      <div class="echarts-box">
        <div class="echarts">
          <div ref="statisticsLine" style="height: 410px; width: 100%"></div>
        </div>
        <div class="notes">
          <div v-for="(item) in workList" :key="item.id" class="con">
            <Iconfont
              name="icon-circle"
              :size="18"
              :color="item.color"
              class="iconfont"
            ></Iconfont>
            <span>{{item.text}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { workStatistics } from "@/api/getData";
import { getWeek, getTimes } from "@/utils/getDate";
import dayjs from "dayjs";
export default {
  name: "workAnalyze",
  data() {
    return {
      startTime: getWeek()[1],
      endTime: getWeek()[2],
      pieStartTime: "",
      pieEndTime: "",
      lineStartTime: "",
      lineEndTime: "",
      pieStartFastTime: getWeek()[1],
      pieEndFastTime: getWeek()[2],
      lineStartFastTime: getWeek()[1],
      lineEndFastTime: getWeek()[2],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      pieTimeType: 1,
      lineTimeType: 1,
      tableData: [],
      workList: [
        // { id: 1, text: "行走状态：车辆正在行驶中的状态", color: "#FFBB33" },
        { id: 2, text: "通电状态：车辆通电但未启动发动机状态", color: "#61BBFF" },
        { id: 3, text: "怠速状态：发动机启动处于空挡状态", color: "#FFF27D" },
        { id: 4, text: "作业状态：车辆正在负荷运行状态", color: "#B7E77A" },
        { id: 5, text: "熄火状态：发动机熄火断电状态", color: "#D9D9D9" }
      ],
      vehicleIds: [],
      requestNum: 0,
      echartsData: [{}, {}]
    };
  },
  props: {
    option: {
      type: Array,
      default: function () {
        return [];
      },
    },
    vehicleId: {
      type: Array,
      default: function () {
        return [];
      },
    },
    groupIds: {
      type: Array,
      default: function () {
        return [];
      },
    }
  },
  watch: {
    vehicleId: {
      handler(val) {
        this.requestNum = 1;
        this.vehicleIds = this.vehicleId;

        this.getWorkStatistics('setPieEcharts');
        this.getWorkStatistics('setLineEcharts');
      },
      // immediate: true,
      deep: true
    },
    groupIds: {
      handler(val) {
        if (!this.requestNum) {
          this.getWorkStatistics('setPieEcharts');
          this.getWorkStatistics('setLineEcharts');
        }
      },
      // immediate: true,
      deep: true
    }
  },
  created() {
    this.vehicleIds = this.vehicleId;
    // this.echartsData = this.option;
    this.getWorkData();
  },
  mounted() {

  },
  methods: {
    async getWorkStatistics(type) {
      if (this.startTime && !this.endTime) {
        this.$message({
          message: '请选择结束时间！',
          type: 'warning'
        });
        return false;
      }
      if (!this.startTime && this.endTime) {
        this.$message({
          message: '请选择开始时间！',
          type: 'warning'
        });
        return false;
      }
      if (this.startTime && this.endTime && (new Date(this.startTime) > new Date(this.endTime))) {
        this.$message({
          message: '请选择正确的开始时间和结束时间！',
          type: 'warning'
        });
        return false;
      }
      await workStatistics({
        pageNumber: 1,
        pageSize: 10,
        vehicleIds: this.vehicleIds,
        startTime: type == 'setPieEcharts' ? this.pieStartFastTime : this.lineStartFastTime,
        endTime: type == 'setPieEcharts' ? this.pieEndFastTime : this.lineEndFastTime,
        groupIds: this.groupIds,
        intervalMark: type == 'setPieEcharts' ? this.pieTimeType : this.lineTimeType,
        //   flag: false,
        deviceStates: [],
        productLines: [],
        vehicleBrands: []

      }).then(res => {
        if (res.flag == 1) {
          if (type == "setLineEcharts") {
            if (res.obj && res.obj.vehicleStatisticalList) {
              //   let vehicleStatisticalList = res.obj.vehicleStatisticalList.reverse();
              let vehicleStatisticalList = res.obj.vehicleStatisticalList;
              this.echartsData[1] = vehicleStatisticalList;
              this.$message({
                type: "success",
                duration: 2000,
                message: "工时走势获取成功"
              });
            }
          };
          if (type == "setPieEcharts") {
            let numData = {
              0: 0,
              1: 0,
              2: 0,
              3: 0,
              4: 0,
            };
            numData[0] = res.obj.walkTime ? res.obj.walkTime : 0;
            numData[1] = res.obj.electrifyTime ? res.obj.electrifyTime : 0;
            numData[2] = res.obj.idleTime ? res.obj.idleTime : 0;
            numData[3] = res.obj.workTime ? res.obj.workTime : 0;
            numData[4] = res.obj.flameOutTime ? res.obj.flameOutTime : 0;
            this.echartsData[0] = numData;
            this.$message({
              type: "success",
              duration: 2000,
              message: "工时占比获取成功"
            });
          }
        }
      });
      this.getWorkData(type);
    },
    async getWorkData(type) {
      let { numData, xData, series } = await this.processPostData();
      if (type) {
        this[type](numData, xData, series);
      } else {
        this.setPieEcharts(numData, xData, series);
        this.setLineEcharts(numData, xData, series);
      }
    },
    setPieEcharts(numData, xData, series) {
      let oData = [
        // {
        //   value: numData[0],
        //   name: "行走",
        //   label: {
        //     formatter: [
        //       "{b}",
        //       "{he|}",
        //       "{hr|}",
        //       "{he|}",
        //       "{valueHead|{d}%}",
        //     ].join("\n"),
        //     //   {val.name}</div><div>${val.percent}
        //     rich: {
        //       title: {
        //         align: "center",
        //       },
        //       hr: {
        //         borderColor: "#FFFFBB33",
        //         width: "100%",
        //         borderWidth: 0.5,
        //         height: 0,
        //       },
        //       he: {
        //         height: 2,
        //       },
        //       valueHead: {
        //         color: "#333",
        //         align: "center",
        //       },
        //     },
        //   },
        //   itemStyle: {
        //     color: "#FFBB33"
        //   }
        // },
        {
          value: numData[1],
          name: "通电",
          label: {
            formatter: [
              "{b}",
              "{he|}",
              "{hr|}",
              "{he|}",
              "{valueHead|{d}%}",
            ].join("\n"),
            //   {val.name}</div><div>${val.percent}
            rich: {
              title: {
                align: "center",
              },
              hr: {
                borderColor: "#FFBB33",
                width: "100%",
                borderWidth: 0.5,
                height: 0,
              },
              he: {
                height: 2,
              },
              valueHead: {
                color: "#333",
                align: "center",
              },
            },
          },
          itemStyle: {
            color: "#61BBFF"
          }
        },
        {
          value: numData[2],
          name: "怠速",
          label: {
            formatter: [
              "{b}",
              "{he|}",
              "{hr|}",
              "{he|}",
              "{valueHead|{d}%}",
            ].join("\n"),
            //   {val.name}</div><div>${val.percent}
            rich: {
              title: {
                align: "center",
              },
              hr: {
                borderColor: "#FFE03A",
                width: "100%",
                borderWidth: 0.5,
                height: 0,
              },
              he: {
                height: 2,
              },
              valueHead: {
                color: "#333",
                align: "center",
              },
            },
          },
          itemStyle: {
            color: "#FFF27D"
          }
        },
        {
          value: numData[3],
          name: "作业",
          label: {
            formatter: [
              "{b}",
              "{he|}",
              "{hr|}",
              "{he|}",
              "{valueHead|{d}%}",
            ].join("\n"),
            //   {val.name}</div><div>${val.percent}
            rich: {
              title: {
                align: "center",
              },
              hr: {
                borderColor: "#FFE03A",
                width: "100%",
                borderWidth: 0.5,
                height: 0,
              },
              he: {
                height: 2,
              },
              valueHead: {
                color: "#333",
                align: "center",
              },
            },
          },
          itemStyle: {
            color: "#B7E77A"
          }
        },
        {
          value: numData[4],
          name: "熄火",
          label: {
            formatter: [
              "{b}",
              "{he|}",
              "{hr|}",
              "{he|}",
              "{valueHead|{d}%}",
            ].join("\n"),
            //   {val.name}</div><div>${val.percent}
            rich: {
              title: {
                align: "center",
              },
              hr: {
                borderColor: "#FFE03A",
                width: "100%",
                borderWidth: 0.5,
                height: 0,
              },
              he: {
                height: 2,
              },
              valueHead: {
                color: "#333",
                align: "center",
              },
            },
          },
          itemStyle: {
            color: "#D9D9D9"
          }
        },
      ];
      let option = {
        tooltip: {
          trigger: "item",
          //  formatter: "{b} : {c} ({d}%)",
          formatter: function (data) {
            let str = "";
            const values = data['data'].value;
            const tooltips = `${ parseInt(values / 3600) }时${ Math.floor((values / 60) % 60) }分`;
            str += data['name'] + '：' + tooltips + " " + "(" + data['percent'] + "%" + ")";
            return str;
          }
        },
        // color: ["#FFBB33", "#61BBFF", "#FFF27D", "#B7E77A", "#D9D9D9"],
        legend: {
          show: true,
          itemWidth: 10,
          itemHeight: 10,
          icon: "roundRect",
          bottom: 0,
          left: "center",
          textStyle: {
            fontSize: '14px',
            fontWeight: 700
          },
          // orient:"horizontal","行走", 
          data: ["通电", "怠速", "作业", "熄火"]
        },

        series: [
          {
            type: "pie",
            radius: "65%",
            center: ["50%", "45%"],
            selectedMode: "single",
            data: oData.filter(val => val.value),
            label: {
              distanceToLabelLine: 0,
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      let lineBox = echarts.init(this.$refs.lineBox, null, {});
      lineBox.setOption(option);
    },
    setLineEcharts(numData, xData, series) {
      let option = {
        grid: {
          left: '3%',
          bottom: '20%',
          top: '28px',
          containLabel: true
        },
        legend: {
          // orient: 'vertical',
          // top: 'middle',
          bottom: -10,
          left: "center",
          itemWidth: 10, //设置宽度
          itemHeight: 10, //设置高度
          itemGap: 18,
          icon: "roundRect",
          textStyle: {
            fontSize: '14px',
            fontWeight: 700
          },
          margin: [0, 0, -10, 0],
          padding: 10
        },
        xAxis: {
          name: '日期',
          boundaryGap: false,
          type: 'category',
          data: xData,
          axisLine: {
            lineStyle: {
              type: "dashed",
              color: "#cccccc"
            }
          },
          splitLine: {
            show: false,
            //   lineStyle: {
            //       type: "dashed",
            //       color: "#EFEFEF"
            //   }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#333333', //更改坐标轴文字颜色
              // fontSize: w2      //更改坐标轴文字大小
            }
          },
          nameTextStyle: {
            // fontSize: 8      //更改坐标轴文字大小
          },
        },
        yAxis: {
          type: 'value',
          name: '工时/小时',
          axisLabel: {
            show: true,
            textStyle: {
              color: '#333333',  //更改坐标轴文字颜色
              // fontSize: w2      //更改坐标轴文字大小
            }
          },
          nameTextStyle: {
            // fontSize: 8      //更改坐标轴文字大小
          },
          axisLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: '#cccccc'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#EFEFEF"
            }
          }

        },
        //   dataZoom: [
        //     {
        //       type: "slider",
        //       show: true,
        //       handleSize: 5,
        //       height: 12,
        //       // startValue: 0,
        //       // endValue: 6,
        //       filterMode: "filter",
        //     },
        //     {
        //       type: "inside",
        //       xAxisIndex: [0],
        //       // startValue: 0,
        //       // endValue: 6,
        //     },
        //   ],
        series: [
          // {
          //   name: "行走",
          //   data: series[0],
          //   type: 'line',
          //   smooth: 0.6,
          //   itemStyle: {
          //     normal: {
          //       color: '#FFBB33', //折点颜色
          //       lineStyle: {
          //         color: '#FFBB33' //折线颜色
          //       }
          //     }
          //   },
          //   areaStyle: {
          //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          //       offset: 0,
          //       color: 'rgba(255, 187, 51, 1)'
          //     }, {
          //       offset: 1,
          //       color: 'rgba(255, 255, 255, 0.3)'
          //     }])
          //   },
          // },
          {
            name: "通电",
            data: series[1],
            type: 'line',
            smooth: 0.6,
            itemStyle: {
              normal: {
                color: '#61BBFF', //折点颜色
                lineStyle: {
                  color: '#61BBFF' //折线颜色
                }
              }
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(97, 187, 255, 1)'
              }, {
                offset: 1,
                color: 'rgba(255, 255, 255, 0.3)'
              }])
            },
          },
          {
            name: "怠速",
            data: series[2],
            type: 'line',
            smooth: 0.6,
            itemStyle: {
              normal: {
                color: '#FFF27D', //折点颜色
                lineStyle: {
                  color: '#FFF27D' //折线颜色
                }
              }
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(255, 242, 125, 1)'
              }, {
                offset: 1,
                color: 'rgba(255, 255, 255, 0.3)'
              }])
            },
          },
          {
            name: "作业",
            data: series[3],
            type: 'line',
            smooth: 0.6,
            itemStyle: {
              normal: {
                color: '#B7E77A', //折点颜色
                lineStyle: {
                  color: '#B7E77A' //折线颜色
                }
              }
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(183, 231, 122, 1)'
              }, {
                offset: 1,
                color: 'rgba(255, 255, 255, 0.3)'
              }])
            },
          }, {
            name: "熄火",
            data: series[4],
            type: 'line',
            smooth: 0.6,
            itemStyle: {
              normal: {
                color: '#D9D9D9', //折点颜色
                lineStyle: {
                  color: '#D9D9D9' //折线颜色
                }
              }
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(217, 217, 217, 1)'
              }, {
                offset: 1,
                color: 'rgba(217, 217, 217, 1)'
              }])
            },
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            label: {}
          },
          formatter: function (params) {
            let str = '';
            str += params[0].axisValue + '<br>';
            params.map(val => {
              str += val.marker + val.seriesName + '：' + `${ parseInt(val.value) }时${ parseInt((val.value * 3600 / 60) % 60) }分 ` + '<br>';
            });
            return str;
          },
        },
      };
      let statisticsLine = echarts.init(this.$refs.statisticsLine, null, {});
      statisticsLine.setOption(option);
    },
    handleSelectionChange(val) {
      console.log(val);
    },
    tableRowClassName({ row, rowIndex }) {
      // "check-row"
      return "";
    },
    handleChangeType(val, type) {
      type == 'setPieEcharts' ? this.pieStartFastTime = getTimes(val)[0] : this.lineStartFastTime = getTimes(val)[0];
      type == 'setPieEcharts' ? this.pieEndFastTime = getTimes(val)[1] : this.pieEndFastTime = getTimes(val)[1];
      type == 'setPieEcharts' ? this.pieTimeType = val : this.lineTimeType = val;
      this.getWorkStatistics(type);
    },
    hadnleChangeTime(val) {
      let startTime = "";
      let endTime = "";
      if (val == 'setPieEcharts') {
        startTime = this.pieStartTime;
        endTime = this.pieEndTime;
        this.pieTimeType = 0;
      } else {
        startTime = this.lineStartTime;
        endTime = this.lineEndTime;
        this.lineTimeType = 0;
      }
      if (startTime && !endTime) {
        this.$message({
          message: "请选择结束时间！",
          type: "warning",
        });
        return false;
      } else if (!startTime && endTime) {
        this.$message({
          message: "请选择开始时间！",
          type: "warning",
        });
        return false;
      } else if (
        startTime &&
        endTime &&
        new Date(startTime) > new Date(endTime)
      ) {
        this.$message({
          message: "请选择正确的开始时间和结束时间！",
          type: "warning",
        });
        return false;
      } else {
        if (val == 'setPieEcharts') {
          this.pieStartFastTime = dayjs(startTime).format("YYYY-MM-DD 00:00:00");
          this.pieEndFastTime = dayjs(endTime).format("YYYY-MM-DD 23:59:59");
          // startTime = this.pieStartTime;
          // endTime = this.pieEndTime;
        } else {
          this.lineStartFastTime = dayjs(startTime).format("YYYY-MM-DD 00:00:00");
          this.pieEndFastTime = dayjs(endTime).format("YYYY-MM-DD 23:59:59");
        }
        // this.startTime = dayjs(startTime).format("YYYY-MM-DD 00:00:00");
        // this.endTime = dayjs(endTime).format("YYYY-MM-DD 23:59:59");

        this.getWorkStatistics(val);
      }
    },
    processPostData() {
      //饼图数据
      let numData = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
      };
      let xData = [];

      //折线图数据
      let series = {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
      };
      if (this.echartsData && this.echartsData[0]) {
        const sumDatas = this.echartsData[0];
        numData[0] = sumDatas[0];
        numData[1] = sumDatas[1];
        numData[2] = sumDatas[2];
        numData[3] = sumDatas[3];
        numData[4] = sumDatas[4];
      };
      for (const key in this.echartsData[1]) {
        if (this.lineTimeType == 3) {
          xData.push(this.echartsData[1][key].dateDay.substr(0, 7));
        } else {
          xData.push(this.echartsData[1][key].dateDay);
        }
        if (this.echartsData[1][key]) {
          if (this.echartsData[1][key].walkingTime) {
            series[0].push(this.echartsData[1][key].walkingTime / 3600);
          } else {
            series[0].push(0);
          }
          if (this.echartsData[1][key].electricityTime) {
            series[1].push(this.echartsData[1][key].electricityTime / 3600);
          } else {
            series[1].push(0);
          }
          if (this.echartsData[1][key].idleSpeed) {
            series[2].push(this.echartsData[1][key].idleSpeed / 3600);
          } else {
            series[2].push(0);
          }
          if (this.echartsData[1][key].workTime) {
            series[3].push(this.echartsData[1][key].workTime / 3600);
          } else {
            series[3].push(0);
          }
          if (this.echartsData[1][key].shutdownTime) {
            series[4].push(this.echartsData[1][key].shutdownTime / 3600);
          } else {
            series[4].push(0);
          }
        } else {
          series[0].push(0);
          series[1].push(0);
          series[2].push(0);
          series[3].push(0);
          series[4].push(0);
        }
      };
      return { numData, xData, series };
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics-box {
  background-color: #fff;
  padding-bottom: 38px;
  overflow: hidden;
  > .title {
    display: flex;
    justify-content: space-between;
    margin: 0 24px;
    line-height: 48px;
    border-bottom: 1px dashed #cccccc;
    > .name {
      position: relative;
      padding-left: 10px;
      font-size: 20px;
      font-weight: bold;
      color: #666666;
    }
    > .name::after {
      content: '';
      position: absolute;
      left: 0px;
      top: 15px;
      height: 16px;
      width: 4px;
      background-color: rgba(66, 120, 201, 1);
    }
    > .time {
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;
      color: #666666;
      > .ash {
        color: #666666;
      }
    }
  }
  > .echarts-box {
    display: flex;
    margin-top: 16px;
    min-width: 1000px;
    > .echarts {
      flex: 1;
      > .num-box {
        display: flex;
        justify-content: space-between;
        width: 350px;
        margin: auto;
        > div {
          text-align: center;
          > div {
            font-size: 12px;
            color: #666666;
            margin-top: 4px;
          }
          > span {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
          }
        }
      }
    }
    > .notes {
      width: 30%;
      margin-right: 8px;
      margin-left: 4px;
      .con {
        margin: 20px 0;
        .iconfont {
          margin-right: 10px;
        }
        span {
          font-size: 16px;
        }
      }
    }
  }
}
.check-row {
  background-color: rgba(220, 232, 252, 1);
}
::v-deep .el-input--small .el-input__inner {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
}
</style>
<style>
.myBox {
  min-width: 300px !important;
}
</style>