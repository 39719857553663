<template>
  <div style="margin-top: 10px; height: calc(100% - 10px)">
    <RouterKit :contentList="contentList"
      title="工时管理"
      @change="handleChangePath">
      <template v-slot:form>
        <Form :groupCount="groupCountList"
          ref="formBox"
          @change="hadnleChangeForm"
          @export="handleExport"
          :isExport="componentName=='statistics'"
          style="margin-bottom: 10px" />
      </template>
      <template v-slot:route>
        <keep-alive>
          <component :is="componentName"
            :vehicleId="vehicleId"
            :groupIds="groupIds"
            ref="componentRef"
            :option="option"
            :tableDatas="tableDatas" />
        </keep-alive>
      </template>
    </RouterKit>
  </div>
</template>

<script>
import RouterKit from "@/components/RouterKit/index";
import Form from "@/components/RouterKit/components/Form";
import NoPermission from "@/components/RouterKit/components/NoPermission.vue";
import { groupCount, groupCountForAlarm } from "@/api/getData";
import statistics from "@/views/workManagement/components/statistics";
import analyze from "@/views/workManagement/components/analyze";
import { getWeek } from "@/utils/getDate";
import { workStatistics, workTables, workDownLoad } from "@/api/getData";
export default {
  name: "workManagement",
  components: {
    RouterKit,
    Form,
    statistics,
    analyze,
    NoPermission
  },
  data() {
    return {
      groupCountList: [],
      tableDatas: [],
      componentName: "statistics",
      contentList: [
        {
          path: "statistics",
          title: "数据统计",
          iconName: "icon-shujutongji",
        },
        {
          path: "analyze",
          title: "数据分析",
          iconName: "icon-shujufenxi",
        },
        {
          path: "NoPermission",
          title: "数据管理",
          iconName: "icon-shujuguanli",
        },
      ],
      getData: {
        pageNumber: 1,
        pageSize: 10,
        vehicleIds: [],
        startTime: getWeek()[1],
        endTime: getWeek()[2],
        groupIds: [],
        intervalMark: 1,
        deviceVehicleIds: [],
        productVehicleIds: [],
        brandVehicleIds: []

      },
      vehicleId: [],
      groupIds: [],
      option: [],
    };
  },
  created() {
    this.init();
    // this.getWorkStatistics();
  },
  methods: {
    async init() {
      // title   分类名称
      // list     分类数据数组
      // backName  返回字段名称
      let groupCountList = [];
      let group = await groupCount();

      if (group.flag == 1) {
        if (group.obj.stateCountData) {
          let num = 0;
          group.obj.stateCountData.map((val) => {
            num += val.sumResult;
          });
          group.obj.stateCountData.unshift({
            label: "全部",
            labelValue: "",
            sumResult: num,
          });
          groupCountList.push({
            title: "设备状态",
            list: group.obj.stateCountData,
            backName: "stateCount", //选择后回调字段
          });
        }
        if (group.obj.productLineCountData) {
          let num = 0;
          group.obj.productLineCountData.map((val) => {
            num += val.sumResult;
          });
          group.obj.productLineCountData.unshift({
            label: "全部",
            labelValue: "",
            sumResult: num,
          });
          groupCountList.push({
            title: "产品线",
            list: group.obj.productLineCountData,
            backName: "product", //选择后回调字段
          });
        }
        if (group.obj.brandCountData) {
          let num = 0;
          group.obj.brandCountData.map((val) => {
            num += val.sumResult;
          });
          group.obj.brandCountData.unshift({
            label: "全部",
            labelValue: "",
            sumResult: num,
          });
          groupCountList.push({
            title: "品牌分类",
            list: group.obj.brandCountData,
            backName: "brandCount", //选择后回调字段
          });
        }
      }
      this.groupCountList = groupCountList;
    },

    //  获取柱状图与文字数据
    async getWorkStatistics() {
      await this.getWorkTables();
      workStatistics(this.getData).then(res => {
        if (res.flag == 1) {
          //文字描述数据  &&  饼图数据
          let numData = {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0
          };
          numData[0] = res.obj.walkTime ? res.obj.walkTime : 0;
          numData[1] = res.obj.electrifyTime ? res.obj.electrifyTime : 0;
          numData[2] = res.obj.idleTime ? res.obj.idleTime : 0;
          numData[3] = res.obj.workTime ? res.obj.workTime : 0;
          numData[4] = res.obj.flameOutTime ? res.obj.flameOutTime : 0;
          numData[5] = res.obj.dayAverage ? res.obj.dayAverage : 0;
          numData[6] = res.obj.utilization ? res.obj.utilization : 0;

          //折线图 & 柱状图数据
         //  const vehicleStatisticalList = res.obj.vehicleStatisticalList.reverse();
          const vehicleStatisticalList = res.obj.vehicleStatisticalList;
          this.option.push(numData, vehicleStatisticalList);
        };
        this.componentName = "statistics";
      });
    },
    //  获取表格数据
    async getWorkTables() {
      await workTables(this.getData).then(res => {
        if (res.flag == 1) {
          if (res && res.rows) {
            const { rows } = res;
            this.tableDatas = rows ? rows : [];

            this.$message({
              type: "success",
              duration: 1000,
              message: "工时统计获取成功",
            });
          }
        };
        // this.componentName = "statistics";
      });
    },
    //下载工时
    async handleExport(e) {
      await workDownLoad({
        pageNumber: 1,
        pageSize: 10,
        vehicleIds: e.vehicleIds,
        intervalMark: this.$refs.componentRef.timeType,
        startTime: this.$refs.componentRef.startTime,
        endTime: this.$refs.componentRef.endTime,
        groupIds: e.groupIds,
        productLines: [],
        vehicleBrands: [],
        responseType: 'blob'
      });
      // .then(res => {
      //   if (res.flag == 1) {
      //   };
      // });
    },

    handleChangePath(val) {
      this.componentName = val;
      // this.$refs.formBox.handleInit();
    },
    hadnleChangeForm(val) {
      if (val.vehicleIds) {
        this.vehicleId = val.vehicleIds.split(",").map(Number);
      } else {
        this.vehicleId = [];
      }
      if (val.groupIds) {
        // this.groupIds = [val.groupIds].map(Number);
          this.groupIds = val.groupIds.split(",").map(Number);
      } else {
        this.groupIds = [];
      }

    }
  },
};
</script>

<style>
</style>